import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useCallback, useState } from 'react';
import { useList } from 'react-use';

import { Order } from '../models/order';
import { convertSorterResultsToRequestOrder } from '../utils/converters';
import { isNonEmptyObject } from '../utils/object';

export function useTableOptions<T>(defaultOrder?: [keyof T, Order][], defaultPageSize = 10) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [order, { set: setOrder }] = useList<[keyof T, Order]>(defaultOrder ?? []);

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      _filters: Record<string, FilterValue | null>,
      sorter: SorterResult<T> | SorterResult<T>[],
      _extra: TableCurrentDataSource<T>
    ) => {
      setPage(pagination.current);
      setPageSize(pagination.pageSize);
      setOrder(!defaultOrder || isNonEmptyObject(sorter) ? convertSorterResultsToRequestOrder(sorter) : defaultOrder);
    },
    [defaultOrder, setOrder]
  );

  return { page, setPage, pageSize, setPageSize, order, setOrder, handleTableChange };
}
